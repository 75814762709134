<template>
    <div class="sector">
        <div class="sector-head">
            <h1 class="sector-head-title">{{ titles.sector }}</h1>
        </div>
        <div class="box-table">
            <v-data-table
                :headers="headers"
                :items="companies"
                :page.sync="pagination.page"
                :items-per-page="pagination.itemsPerPage"
                hide-default-footer
                class="data-table elevation-2"
                @page-count="pagination.pageCount = $event">
                <template v-slot:[`item.index`]="{ item }"> {{ item.index }}</template>

                <template v-slot:[`item.avatar`]="{}">
                    <v-avatar color="#dddddd" size="40"></v-avatar>
                </template>

                <template v-slot:[`item.groups`]="{ item }">
                    <v-btn @click="getGroups(item.id)" icon title="Visualizar">
                        {{ item.groups }}
                    </v-btn>
                </template>

                <template v-slot:[`item.gender`]="{ item }">
                    <v-icon :color="getIconGenderComputed(item.gender).color">
                        {{ getIconGenderComputed(item.gender).icon }}
                    </v-icon>
                </template>

                <template v-slot:[`item.checked`]="{ item }">
                    <v-icon :color="getIconActiveComputed(item.checked).color">
                        {{ getIconActiveComputed(item.checked).icon }}
                    </v-icon>
                </template>

                <template v-slot:[`item.createdAt`]="{ item }">
                    {{ formatDateComputed(item.createdAt) }}
                </template>

                <template v-slot:[`item.active`]="{ item }">
                    <v-icon :color="getIconActiveComputedComputed(item.active).color">
                        {{ getIconActiveComputedComputed(item.active).icon }}
                    </v-icon>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-progress-circular
                        :size="20"
                        color="gray"
                        v-show="item.stateActive"
                        indeterminate>
                    </v-progress-circular>
                    <v-btn
                        v-show="!item.stateActive"
                        :title="getToggleActiveComputed(item.active).title"
                        class="btn-action"
                        x-small
                        elevation="1"
                        rounded
                        plain
                        :disabled="item.stateActive"
                        :color="getToggleActiveComputed(item.active).color"
                        @click="actionToggleStatusActive(item.id)">
                        {{ getToggleActiveComputed(item.active).title }}
                    </v-btn>
                </template>
            </v-data-table>
            <div class="text-center pt-2">
                <v-pagination
                    v-model="pagination.page"
                    :length="pagination.pageCount"></v-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import companiesAdminMock from '@/modules/admin/mocks/companies.admin.mock';
    import { isAmbientGeralTests } from '@/configs/global.config';

    export default {
        name: 'ListCompany',
        data() {
            return {
                isAmbientLocalTests: false,
                pagination: {
                    page: 1,
                    pageCount: 0,
                    itemsPerPage: 10
                },
                titles: {
                    sector: 'Escolas'
                },
                headers: [
                    { text: '#', value: 'index' },
                    { text: 'Id', value: 'id' },
                    { text: 'Nome', value: 'name' },
                    { text: 'Alunos', value: 'usersLinked', align: 'center' },
                    { text: 'Grupos', value: 'groups', align: 'center' },
                    { text: 'Campanhas', value: 'campaigns', align: 'center' },
                    { text: 'Conta ativada?', value: 'checked', align: 'center' },
                    { text: 'Data cadastro', value: 'createdAt', align: 'center' },
                    { text: 'Ativo?', value: 'active', align: 'center' },
                    { text: 'Ações', value: 'actions', sortable: false, align: 'center' }
                ],
                companies: [],
                groups: [],
                usersLinked: []
            };
        },
        mounted() {
            this.populateGeral();
        },
        computed: {
            getIndexComputed() {
                return this.index++;
            },
            formatDateComputed() {
                return (date) => {
                    return moment(date).format('DD/MM/YYYY');
                };
            },
            getIconActiveComputedComputed() {
                return (active) => {
                    return {
                        icon: 'mdi-check-circle',
                        color: active ? 'green' : '#d0d0d0'
                    };
                };
            },
            getColorComputed() {
                return (active) => (active ? 'green' : 'red');
            },
            getIconGenderComputed() {
                return (gender) => {
                    return {
                        icon: gender === 'f' ? 'mdi-face-woman' : 'face',
                        color: gender === 'f' ? 'pink' : 'blue'
                    };
                };
            },
            getIconActiveComputed() {
                return (checked) => {
                    return {
                        icon: 'mdi-email-check',
                        color: checked ? 'green' : '#d0d0d0'
                    };
                };
            },
            getToggleActiveComputed() {
                return (active) => {
                    return {
                        color: active ? 'red' : 'green',
                        title: active ? 'Desativar' : 'Ativar'
                    };
                };
            }
        },
        methods: {
            isAmbientTests() {
                return isAmbientGeralTests && this.isAmbientLocalTests;
            },
            populateGeral() {
                if (this.isAmbientTests()) {
                    this.companies = companiesAdminMock.companies;
                } else {
                    this.serviceLoadingCompanies();
                }
            },
            async actionToggleStatusActive(companyId) {
                const companyIndex = this.companies.findIndex((e) => e.id === companyId);

                if (companyIndex !== -1) {
                    this.companies[companyIndex].stateActive = true;

                    await this.serviceToggleStatusActive(companyId);

                    await new Promise((resolve) => setTimeout(resolve, 2000));

                    this.companies[companyIndex].active =
                        !this.companies[companyIndex].active;

                    this.companies[companyIndex].stateActive = false;
                }
            },
            async serviceToggleStatusActive(companyId) {
                await this.$service.http['admin']
                    .toggleStatusActiveUser(companyId)
                    .then((_) => null)
                    .catch((e) => {
                        console.error(e);
                    });
            },
            syncronizeDatas() {
                const filterGroups = (companyId) =>
                    this.groups.filter((e) => e.company_id === companyId);

                this.companies.forEach((company, i) => {
                    this.companies[i].index = i + 1;

                    this.companies[i].groups = filterGroups(company.id);
                });
            },

            serviceLoadingCompanies() {
                this.$store.commit('setGlobalLoading', true);

                let query = '?role=COMPANY&option=full';

                this.$service.http['admin']
                    .filterCompanies(query)
                    .then(async (result) => {
                        const records = result.data.docs;

                        this.companies = records;

                        this.companies.forEach((_, i) => {
                            this.companies[i].index = i + 1;

                            this.$set(this.companies[i], 'stateActive', false);
                        });
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            }
        }
    };
</script>

<style scoped>
    .btn-des {
        text-transform: none !important;
        font-weight: bold;
    }

    .btn-action {
        letter-spacing: 0em;
    }

    .btn-action {
        text-transform: none !important;
        font-weight: bold;
    }

    .sector-head {
        margin-bottom: 15px;
    }

    .sector-head-title {
        text-align: left !important;
    }

    .v-data-table-header {
        background-color: #d0d0d0;
    }
</style>
