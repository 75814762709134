export default {
    companies: [
        {
            id: '21314',
            name: 'Santinha Ribeiro',
            email: 'santinha@gmail.com',
            groups: 1,
            users: 20,
            finalized: true,
            active: true,
            createdAt: '2022/10/09'
        },
        {
            id: '21317',
            name: 'Damas Siqueira',
            email: 'damas@gmail.com',
            groups: 1,
            users: 3,
            finalized: false,
            active: false,
            createdAt: '2022/10/09'
        }
    ]
};
