var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sector"},[_c('div',{staticClass:"sector-head"},[_c('h1',{staticClass:"sector-head-title"},[_vm._v(_vm._s(_vm.titles.sector))])]),_c('div',{staticClass:"box-table"},[_c('v-data-table',{staticClass:"data-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.companies,"page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.index))]}},{key:"item.avatar",fn:function(ref){return [_c('v-avatar',{attrs:{"color":"#dddddd","size":"40"}})]}},{key:"item.groups",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","title":"Visualizar"},on:{"click":function($event){return _vm.getGroups(item.id)}}},[_vm._v(" "+_vm._s(item.groups)+" ")])]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getIconGenderComputed(item.gender).color}},[_vm._v(" "+_vm._s(_vm.getIconGenderComputed(item.gender).icon)+" ")])]}},{key:"item.checked",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getIconActiveComputed(item.checked).color}},[_vm._v(" "+_vm._s(_vm.getIconActiveComputed(item.checked).icon)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateComputed(item.createdAt))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getIconActiveComputedComputed(item.active).color}},[_vm._v(" "+_vm._s(_vm.getIconActiveComputedComputed(item.active).icon)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(item.stateActive),expression:"item.stateActive"}],attrs:{"size":20,"color":"gray","indeterminate":""}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!item.stateActive),expression:"!item.stateActive"}],staticClass:"btn-action",attrs:{"title":_vm.getToggleActiveComputed(item.active).title,"x-small":"","elevation":"1","rounded":"","plain":"","disabled":item.stateActive,"color":_vm.getToggleActiveComputed(item.active).color},on:{"click":function($event){return _vm.actionToggleStatusActive(item.id)}}},[_vm._v(" "+_vm._s(_vm.getToggleActiveComputed(item.active).title)+" ")])]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pagination.pageCount},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }